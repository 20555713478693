import React from 'react';
import page from './../../hoc/page/page';
import ReactMarkdown from 'react-markdown';
import Footer from '../../components/Footer/Footer';
import ContactButton from '../../components/ContactButton/ContactButton';


class Page extends React.Component {
    render() {
        const { pageText } = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="inner-page">
                        <ReactMarkdown escapeHtml={false} source={pageText} />
                    </div>
                </div>
                <Footer />
                <ContactButton />
            </div>
        );
    }
}

export default page(Page);
