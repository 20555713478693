import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CustomerAgreement from './CustomerAgreement';

export default class DirectInfo extends Component {
    render() {
        return (
            <Fragment>
                <div className="p-small learn-more bg-ghost-white text-left mt-40">
                    <p className="standard-label">A direct participant will:</p>
                    <p>
                        1. Sign a{' '}
                        <Link target="_blank" to="/participation/index">
                            Terms of Use
                        </Link>
                        directly with the Corda Network Foundation,
                        which covers our Service Level Agreement (
                        <Link target="_blank" to="/service-levels/network-services-sla-production">
                            Production
                        </Link>
                        and/or{' '}
                        <Link target="_blank" to="/service-levels/network-services-sla-preproduction">
                            Pre-production
                        </Link>
                        ).
                    </p>
                    <p>
                        2. Be checked by the Foundation that the{' '} 
                        <Link target="_blank" to="/policy/admission-criteria">
                            admission criteria
                        </Link>
                        are met.
                    </p>
                    <p>
                        3. Be covering its own costs for using the Foundation's services
                        (For information on fees,{' '}
                        <Link target="_blank" to="/participation/membership-tiers">
                            see here.
                        </Link>
                        )
                    </p>
                </div>
                <CustomerAgreement />
            </Fragment>
        );
    }
}
