export const SET_LEGAL_ENTITY = 'SET_LEGAL_ENTITY';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const CURRENT_STEP_SELECT_PARTICIPANT_TYPE =
    'CURRENT_STEP_SELECT_PARTICIPANT_TYPE';
export const CURRENT_STEP_CONFIRM_PARTICIPANT_TYPE =
    'CURRENT_STEP_CONFIRM_PARTICIPANT_TYPE';
export const CURRENT_STEP_SELECT_LEGAL_ENTITY =
    'CURRENT_STEP_SELECT_LEGAL_ENTITY';
export const CURRENT_STEP_CONFIRM_LEGAL_ENTITY =
    'CURRENT_STEP_CONFIRM_LEGAL_ENTITY';
export const CURRENT_STEP_SIGN_TERMS = 'CURRENT_STEP_SIGN_TERMS';
export const CURRENT_STEP_CONFIRM_SIGN_TERMS =
    'CURRENT_STEP_CONFIRM_SIGN_TERMS';
export const CURRENT_STEP_ENTER_BILLING_DETAILS =
    'CURRENT_STEP_ENTER_BILLING_DETAILS';
export const CURRENT_STEP_REENTER_BILLING_DETAILS =
    'CURRENT_STEP_REENTER_BILLING_DETAILS';
export const CURRENT_STEP_CONFIRM_BILLING_DETAILS =
    'CURRENT_STEP_CONFIRM_BILLING_DETAILS';
export const CURRENT_STEP_IDENTITY_CHECKS = 'CURRENT_STEP_IDENTITY_CHECKS';
export const CURRENT_STEP_IDENTITY_COMPLETED =
    'CURRENT_STEP_IDENTITY_COMPLETED';
export const SET_PARTICIPANT_TYPE = 'SET_PARTICIPANT_TYPE';
export const SET_BILLING_DETAILS = 'SET_BILLING_DETAILS';
export const SET_USER_AUTHORIZATION = 'SET_USER_AUTHORIZATION';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const REMOVE_USER_AUTHORIZATION = 'REMOVE_USER_AUTHORIZATION';
export const REMOVE_USER_ROLE = 'REMOVE_USER_ROLE';
export const SET_SIGNED_TERMS = 'SET_SIGNED_TERMS';
export const SET_CURRENT_TERMS = 'SET_CURRENT_TERMS';
export const CURRENT_STEP_START_NODE_CREATION =
    'CURRENT_STEP_START_NODE_CREATION';
export const CURRENT_STEP_CREATE_NODE_CONFIG =
    'CURRENT_STEP_CREATE_NODE_CONFIG';
export const CURRENT_STEP_DOWNLOAD_NODE_CONFIG =
    'CURRENT_STEP_DOWNLOAD_NODE_CONFIG';
export const CURRENT_STEP_STOP_NODE_CREATION =
    'CURRENT_STEP_STOP_NODE_CREATION';
export const CURRENT_STEP_STOP_SPONSEE_NODE_CREATION =
    'CURRENT_STEP_STOP_SPONSEE_NODE_CREATION';
export const SET_DIRECT_USER_CORDANETWORK_NODECONFIG =
    'SET_DIRECT_USER_CORDANETWORK_NODECONFIG';
export const SET_DIRECT_USER_UAT_NODECONFIG = 'SET_DIRECT_USER_UAT_NODECONFIG';

export const SHOW_SPONSORED_PARTICIPANTS = 'SHOW_SPONSORED_PARTICIPANTS';
export const SPONSOR_SELECT_LEGAL_ENTITY = 'SPONSOR_SELECT_LEGAL_ENTITY';
export const SPONSOR_CONFIRM_LEGAL_ENTITY = 'SPONSOR_CONFIRM_LEGAL_ENTITY';
export const SET_SPONSOR_LEGAL_ENTITY = 'SET_SPONSOR_LEGAL_ENTITY';
export const SPONSEES_HOW_TO_SET_UP_A_NODE = 'SPONSEES_HOW_TO_SET_UP_A_NODE';
export const NETWORKS_HOW_TO_SET_UP_A_NODE = 'NETWORKS_HOW_TO_SET_UP_A_NODE';
export const CREATE_SPONSEE_NODE_CONFIG = 'CREATE_SPONSEE_NODE_CONFIG';
export const DOWNLOAD_SPONSEE_NODE_CONFIG = 'DOWNLOAD_SPONSEE_NODE_CONFIG';
export const SHOW_CERTIFICATES = 'SHOW_CERTIFICATES';
export const UPGRADE_TO_PRODUCTION_NETWORK = 'UPGRADE_TO_PRODUCTION_NETWORK';
export const SET_NAV_TREE = 'SET_NAV_TREE';