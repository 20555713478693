// TODO: Add this error codes to the errorMessages constant beyond
// export const INVALID_USER = 'invalid_user';
// export const INVALID_HOOK_KEY = 'Unauthorized';
// export const INVALID_SIGNER_EMAIL = 'invalid_signer_email';
// export const INVALID_CAPTCHA = 'invalid_captcha';
// export const INVALID_EMAIL_ADDRESS = 'invalid_email_address';
// export const INVALID_PARTICIPANT = 'invalid_participant';
// export const CAN_NOT_EDIT_PARTICIPANT = 'can_not_edit_participant';
// export const DOES_NOT_EXIST = 'not_exists';
// export const VALIDATION_ERROR = 'validation_error';
// export const JSON_ERROR = 'json_error';
// export const INVALID_PARTICIPANT_TYPE = 'invalid_participant_type';
// export const MISSING_IDENTITY_CHECK = 'missing_identity_check';
// export const INVALID_EMAIL_DOMAIN = 'invalid_email_domain';
// export const EMAIL_SERVER_FAILURE = 'email_server_failure';
// export const INVALID_COUNTRY_CODE = 'invalid_country_code';
// export const INVALID_SANCTION_CHECK = 'invalid_eligibility_check';
// export const INVALID_SANCTION_CHECK_STATE = 'invalid_eligibility_check_state';
// export const SANCTION_CHECK_CONFLICT = 'eligibility_check_conflict';
// export const UNKNOWN_AGREEMENT = 'unknown_agreement';
// export const WRONG_AGREEMENT = 'wrong_agreement';
// export const INVALID_CONTEXT = 'invalid_context';
// export const INVALID_EXTERNALID = 'invalid_externalId';
// export const NOT_SUPPORTED_EMAIL_DOMAIN = 'not_supported_email_domain';
// export const INVALID_IDENTITY = 'invalid_identity';
// export const MISSING_NAME = 'missing_name';
// export const AGREEMENT_TEMPLATE_ERROR = 'agreement_template_error';
// export const SIGNATURE_REQUEST_EXPIRED = 'signature_request_expired';
// export const SIGNATURE_REQUEST_MISSING = 'signature_request_missing';
// export const INVAID_INVITEE_MAIL =
//     'Could not invite user with this email address.';

export const errorMessages = {
    missing_eligibility_check: 'User did not pass eligibity checks',
    dnb_api_error: 'We cannot connect to the database',
    signature_validation_failed: 'This legal entity is missing in our database',
    agreement_not_signed: 'This agreement has not been signed',
    invalid_token: 'Either your token is invalid or expired',
    missing_work_title: "The work title you've entered is invalid",
    signature_request_in_use:
        'This signature request is already in progress or finished',
    already_exists:
        'The legal entity is already on Corda Network. Please log in to your account to continue.',
    signature_request_invalid:
        'The signature request is either exprired or signed',
    invalid_externalId: 'Your legal entity is already on Corda Network. Please contact info@corda.network if you have any issues accessing the network.',
    invalid_email_domain: 'Please use an email address with the same email domain (e.g. …@r3.com) as the registered email address of this account.',
};
