export const getDomElementSiblings = (domElement) => {
	let siblings = [];
	let sibling = domElement.parentNode.firstChild;

	while (sibling) {
		if (sibling.nodeType === 1 && sibling !== domElement) {
			siblings.push(sibling);
		}
		sibling = sibling.nextSibling
	}

	return siblings;
};