import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import './../../containers/Site/assets/scss/inner-pages.scss';
import { MARKDOWN_SOURCE_BASE_URL } from '../../utils/constant.js';
import ReactGA from 'react-ga';

const page = (WrappedComponent) => {
    return class extends React.Component {
        _isMounted = false;

        constructor(props) {
            super(props);
            this.state = {
                pageText: '',
                loading: false,
            };
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
               this.fetchPage(this.props.urlPath);
            }
        }

        componentDidMount() {
            ReactGA.set({ page: window.location.pathname }); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

            this._isMounted = true;

            this.fetchPage(this.props.urlPath);           
        }

        fetchPage(locationPathname) {
            window.scrollTo(0, 0);

            this.setState({
                loading: true,
            });

            const url = locationPathname;
    
            fetch(`${MARKDOWN_SOURCE_BASE_URL}/${url}`)
                .then((response) => response.text())
                .then((text) => {
                    if (this._isMounted) {
                        this.setState({
                            pageText: text.replace(/\(\//g, '(/#/'),
                            loading: false,
                        });
                    }
                });
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            return (
                <LoadingOverlay
                    active={this.state.loading === true}
                    spinner
                    text="Loading...">
                    <WrappedComponent
                        {...this.props}
                        pageText={this.state.pageText}
                    />
                </LoadingOverlay>
            );
        }
    };
};
export default page;
