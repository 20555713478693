import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CustomerAgreement from './CustomerAgreement';

export default class SponsorInfo extends Component {
    render() {
        return (
            <Fragment>
                <div className="p-small learn-more bg-ghost-white text-left mt-40">
                    <p className="standard-label">
                        A sponsoring participant will:
                    </p>
                    <p>
                        1. Sign a Terms of Use with the Corda Network Foundation,
                        which covers its own and its Sponsee nodes to join Corda Network.
                        It also covers our Service Level Agreement (
                        <Link target="_blank" to="/service-levels/network-services-sla-production">
                            Production
                        </Link>
                        and/or{' '}
                        <Link target="_blank" to="/service-levels/network-services-sla-preproduction">
                            Pre-production
                        </Link>
                        ).

                        A Sponsoring Participant ("Sponsor") may want to pass on relevant clauses to agreement(s) with its Sponsee(s).
                    </p>
                    <p>
                        2. Ensure its Sponsee nodes met the{' '}
                        <Link target="_blank" to="/policy/admission-criteria">
                            admission criteria
                        </Link> of Corda Network.
                    </p>
                    <p>
                        3. Be covering its own and its Sponsee’s costs for using the Foundation
                        (For information on fees,{' '}
                        <Link target="_blank" to="/participation/membership-tiers">
                            see here.
                        </Link>
                        )
                    </p>
                </div>
                <CustomerAgreement />
            </Fragment>
        );
    }
}
