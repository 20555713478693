import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { MARKDOWN_SOURCE_BASE_URL } from '../../utils/constant';
import LoadingOverlay from 'react-loading-overlay';
import ReactMarkdown from 'react-markdown';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            communityFooter: '',
            contactFooter: '',
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        Promise.all([
            fetch(`${MARKDOWN_SOURCE_BASE_URL}/content/footer/_Footer_commmunity.md`),
            fetch(`${MARKDOWN_SOURCE_BASE_URL}/content/footer/_Footer_contact.md`)
        ]).then((responses) => {
            return Promise.all(responses.map(function (response) {
                return response.text();
            }));
        }).then((data) => {
            this.setState({
                communityFooter: data[0],
                contactFooter: data[1]
            });
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.loading === true}
                spinner
                text="Loading...">
                <footer className="footer row bg-onyx py-100">
                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                            <div className="row">
                                <div className="community col-lg-6 col-md-6 col-sm-12">
                                    <ReactMarkdown source={this.state.communityFooter} />
                                </div>
                                <div className="contact col-lg-6 col-md-6 col-sm-12">
                                    <ReactMarkdown source={this.state.contactFooter} />
                                </div>
                                <div className="follow col-lg-2 offset-lg-1 col-md-2 offset-md-1 col-sm-12">
                                    <h5>Follow us</h5>
                                    <Link
                                        to=""
                                        className="solo-link link-with-icon medium mt-0">
                                        Medium
                                    </Link>
                                    <Link
                                        to=""
                                        className="solo-link link-with-icon twitter">
                                        Twitter
                                    </Link>
                                    <Link
                                        to=""
                                        className="solo-link link-with-icon linkedin">
                                        Linkedin
                                    </Link>
                                    <Link
                                        to=""
                                        className="solo-link link-with-icon rss">
                                        Rss
                                    </Link>
                                </div>
                            </div>
                        </div>
                </footer>
            </LoadingOverlay>
        );
    }
}
