import React from 'react';
import { useLocation } from 'react-router-dom';

import './Breadcrumb.scss';
import { DOCUMENT_HISTORY_BASE_URL } from '../../utils/constant.js';
import { transformPathToUrl } from '../../utils/utils';

const Breadcrumbs = ({ ...props }) => {
  let location = useLocation().pathname;
  if (location.endsWith('/')) {
    location = location.slice(0, -1);
  }
  const { flatTree } = props;

  const generateDocumentHistoryLink = (location, tree) => {
    if (!tree.length) {
      return null;
    }

    const activeTreeItem = tree.filter((item) => {
      return (
        transformPathToUrl(item.path) === location
      );
    });
    return activeTreeItem[0]
      ? activeTreeItem[0].path
      : '';
  };

  const generateBradcrumb = (location) => {
    const splittedLocation = location.split('/').filter((splitItem) => splitItem);
    const breadCrumb = splittedLocation.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
          {i < splittedLocation.length - 1 && (
            <i className="mdi mdi-chevron-right"></i>
          )}
        </React.Fragment>
      );
    });

    return breadCrumb;
  };

  return (
    location !== '' && (
      <div className="cnf-path">
        <span className="path">{generateBradcrumb(location)}</span>
        <a
          href={`${DOCUMENT_HISTORY_BASE_URL}/${generateDocumentHistoryLink(
            location,
            flatTree
          )}`}
          target="_blank"
          rel="noopener noreferrer">
          Document History
        </a>
      </div>
    )
  );
};

export default Breadcrumbs;
