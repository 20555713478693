import React from 'react';
import { Link } from 'react-router-dom';
import './ContactButton.scss';

class ContactButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: true,
    };
  }

  componentDidMount(){
    if(window.location.pathname === '/faq/contact-us'){
        this.setState({
            showButton: false
        })
    }
  }

  render() {
    return this.state.showButton ? (
        <div className={`contact-button-container`}>
            <Link
                to="/contact/contact-us"
                rel="noopener noreferrer"
                className="contact-button">
                <span>Contact Us</span>
            </Link>
        </div>
    ) : null;
  }
}


export default ContactButton;
