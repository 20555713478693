import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '../utils/firebaseConfig';

console.warn(JSON.stringify(process.env, null, 2));

const { REACT_APP_ENVIRONMENT } = process.env;

if (REACT_APP_ENVIRONMENT) {
    console.log('Initialising Firebase with', REACT_APP_ENVIRONMENT);
    firebase.initializeApp(firebaseConfig[REACT_APP_ENVIRONMENT]);
} else {
    console.error('Environment configuration loading failed');
}

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
