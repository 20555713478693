import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { getDomElementSiblings } from '../../helpers/dom';
import { transformPathToUrl, transformPathToName } from '../../utils/utils';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.treeBuilder = this.treeBuilder.bind(this);
    this.Menu = this.Menu.bind(this);
    this.menuTree = [];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.menuTree.length && !nextProps.navTree) {
      return false;
    }

    this.menuTree = this.treeBuilder(nextProps.navTree.navTree);

    return true;
  }

  treeBuilder(flatTree) {
    const tree = [];
    const tempNestedTree = [];

    const filteredFlagTree = flatTree
      .filter(
        (item) =>
          item.path.startsWith('content') &&
          !item.path.startsWith('content/home') &&
          !item.path.startsWith('content/footer') &&
          item.path !== 'content'
      )
      .map((object) => ({
        ...object,
        path: object.path.replace('content/', ''),
      }));

    filteredFlagTree.forEach((item, i) => {
      const lvl = item.path.split('/').length - 1;

      if (lvl) {
        if (lvl > tempNestedTree.length) {
          if (filteredFlagTree[i - 1]) {
            tempNestedTree.push(filteredFlagTree[i - 1]);
          } else {
            tempNestedTree.push(filteredFlagTree[i]);
          }
        } else if (lvl < tempNestedTree.length) {
          tempNestedTree.pop();
        }

        const currentLvl = tempNestedTree.length - 1;

        if (Array.isArray(tempNestedTree[currentLvl].children)) {
          tempNestedTree[currentLvl].children.push(item);
        } else {
          tempNestedTree[currentLvl].children = [item];
        }
      } else {
        tempNestedTree.length = 0;
        tree.push(item);
      }
    });

    return tree;
  }

  Menu(items, isTopLevel) {
    if (isTopLevel) {
      if (this.props.uar.isUserAuthorized) {
        if (this.props.upr.role === 'admin') {
          items = items.concat({
            path: '0.admin',
            type: 'tree',
            id: 'users-link',
            children: [
              {
                path: '0.admin/1.direct.md',
                type: 'blob',
              },
              {
                path: '0.admin/2.sponsor.md',
                type: 'blob',
              },
            ],
          });
        } else if (
          this.props.upr.role === 'user' ||
          this.props.upr.role === 'signer'
        ) {
          if (
            this.props.csr.currentStep ===
              actionTypes.CURRENT_STEP_IDENTITY_COMPLETED ||
            this.props.upr.role === 'signer'
          ) {
            if (this.props.upr.role === 'user') {
              items = items.concat({
                path: '0.account',
                type: 'tree',
                id: 'account-link',
                children: [
                  {
                    path: '0.account/1.dashboard',
                    type: 'tree',
                    children: [
                      {
                        path: '0.account/1.dashboard/1.account.md',
                        type: 'blob',
                      },
                      {
                        path: '0.account/1.dashboard/2.networks.md',
                        type: 'blob',
                      },
                      {
                        path: '0.account/1.dashboard/3.billing.md',
                        type: 'blob',
                      },
                    ],
                  },
                ],
              });
            } else if (
              (this.props.ptr.participantType === 'SPONSOR' ||
                this.props.sponsoredLegalEntityReducer.type === 'SPONSOR') &&
              this.props.upr.role !== 'signer'
            ) {
              items = items.concat({
                path: '0.account',
                type: 'tree',
                id: 'account-link',
                children: [
                  {
                    path: '0.account/1.dashboard',
                    type: 'tree',
                    children: [
                      {
                        path: '0.account/1.dashboard/1.participants.md',
                        type: 'blob',
                      },
                    ],
                  },
                ],
              });
            }
          } else {
            items = items.concat({
              path: '0.account',
              type: 'tree',
              id: 'account-link',
              children: [
                {
                  path: '0.account/1.onboarding.md',
                  type: 'blob',
                  text:
                    'Network onboarding is in progress. Once completed, you will find all account links in this menu.',
                  id: 'onboarding-item',
                },
              ],
            });
          }
        }
      }
    }

    const mdFileExtension = '.md';

    if (!items.length) {
      return;
    }

    const handleSubmenuOpenerClick = (e, menuItemType) => {
      if (menuItemType === 'tree') {
        e.stopPropagation();
        if (e.target.parentElement.classList.contains('submenu-link')) {
          e.target.parentElement.classList.add('active');
          const elementSiblings = getDomElementSiblings(e.target.parentElement);
          elementSiblings.forEach((element) => {
            element.classList.remove('active');
          });
        }
      } else {
        this.handleClick(e);
      }
    };

    const currentLocationToLevels = window.location.pathname
      .split('/')
      .filter((el) => el);
    const currentLevel = items[0].path.split('/').length;

    return (
      <ul className="nested-menu">
        {items
          .sort((a, b) => {
            return (
              parseInt(a.path.split('/')[a.path.split('/').length - 1]) -
              parseInt(b.path.split('/')[b.path.split('/').length - 1])
            );
          })
          .filter((item) => {
            return !item.path.startsWith('_');
          })
          .filter((item) => {
            return !item.path.includes('/_');
          })
          .map((item) => {
            const splittedItemPath = item.path.split('/');
            const isSubMenuOpenerActive =
              currentLocationToLevels[currentLevel - 1] ===
              splittedItemPath[currentLevel - 1].replace(/\d+(?!.md)\./g, '');

            return (
              <li
                key={Math.random()}
                className={`${
                  item.type === 'tree'
                    ? isSubMenuOpenerActive
                      ? 'submenu-link active'
                      : 'submenu-link'
                    : ''
                } ${item.id ? item.id : ''}`}
                onClick={(e) => handleSubmenuOpenerClick(e, item.type)}>
                {item.type === 'tree' && (
                  <span
                    className="submenu-link-text"
                    id={`${item.id ? item.id : ''}`}>
                    {transformPathToName(item.path, currentLevel)}
                  </span>
                )}

                {item.type === 'blob' && item.path.endsWith(mdFileExtension) ? (
                  <>
                    {item.text && (
                      <span className="in-progress-text">{item.text}</span>
                    )}
                    <NavLink
                      className={`nav-link`}
                      to={transformPathToUrl('/' + item.path)}>
                      {transformPathToName(item.path, currentLevel)}
                    </NavLink>
                  </>
                ) : null}
                {item.children ? this.Menu(item.children, false) : null}
              </li>
            );
          })}
      </ul>
    );
  }

  handleClick(e) {
    e.stopPropagation();
    if (!this.state.isMenuOpen) {
      document.addEventListener('click', this.handleOutsideClick);
    } else {
      document.removeEventListener('click', this.handleOutsideClick);
    }

    if (e.target.classList.contains('submenu-link')) {
      return;
    } else {
      this.setState((prevState) => ({
        isMenuOpen: !prevState.isMenuOpen,
      }));
    }
  }

  handleOutsideClick(e) {
    if (!this.node.contains(e.target)) {
      this.setState({
        isMenuOpen: false,
      });
    }
  }

  closeMenu(e) {
    this.setState({
      isMenuOpen: false,
    });
  }

  render() {
    const isMenuOpen = this.state.isMenuOpen ? 'open' : '';
    return (
      <div className={`navigation ${isMenuOpen}`}>
        <div
          className="navigation-wrapper"
          ref={(node) => {
            this.node = node;
          }}>
          <div className="navbar">
            <NavLink
              to="/"
              className="logo box--flex center-Xxx"
              onClick={this.closeMenu}>
              corda network
            </NavLink>
            <div className="hamburger-menu" onClick={this.handleClick}>
              <span className="line top" />
              <span className="line middle" />
              <span className="line bottom" />
            </div>
          </div>
          <div className="inner-nav">
            <div className="container-fluid">
              <div className="searchfield row">
                <div className="col-11 p-0 box--flex center--y">
                  <div className="col-11 offset-1">
                    <form className="search-form w-100" action="/search">
                      <div className="form-group w-100">
                        <input
                          type="text"
                          autoComplete="off"
                          name="q"
                          id="search-term"
                          className="form-control"
                          placeholder="Search"
                        />
                        <button
                          className="btn btn-search"
                          type="submit"></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="nav-main row">
                {/* USER LOGIN/REGISTER */}
                {/* {!this.props.uar.isUserAuthorized && (
                  <div className="menu-not-authenticated">
                    <ul className="col-10 offset-1 box--flex center--y center--xxX h-100">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/login"
                          onClick={this.handleClick}>
                          Log in
                        </NavLink>
                      </li>
                      <li className="nav-item ml-40">
                        <NavLink
                          className="nav-link"
                          to="/register"
                          onClick={this.handleClick}>
                          Register
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )} */}
                <div
                  className={
                    this.props.uar.isUserAuthorized
                      ? 'menu user-authorized col-lg-12 col-md-12 col-sm-12 p-0'
                      : 'menu col-lg-12 col-md-12 col-sm-12 p-0'
                  }>
                  <nav className="main-menu">
                    <div className="col-11 offset-1">
                      {this.menuTree.length && this.Menu(this.menuTree, true)}
                    </div>
                  </nav>
                </div>
                <div className="social-networks col-lg-1 col-md-1 col-sm-12 box--flex center--y center--x">
                  <ul>
                    <li>
                      <NavLink className="nav-link" to="">
                        <i className="mdi mdi-medium"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="">
                        <i className="mdi mdi-twitter"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="">
                        <i className="mdi mdi-linkedin"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="">
                        <i className="mdi mdi-rss"></i>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uar: state.uar,
    upr: state.upr,
    csr: state.csr,
    ptr: state.ptr,
    sponsoredLegalEntityReducer: state.sponsoredLegalEntityReducer.legalEntity,
    navTree: state.navTree,
  };
};

export default connect(mapStateToProps, null)(Navigation);
