import React, { Component } from 'react';
import './Login.scss';
import LoadingOverlay from 'react-loading-overlay';
import * as actionTypes from '../../store/actions';
import { axiosLogin } from '../../axios/axios';
import Recaptcha from 'react-google-invisible-recaptcha';
import { connect } from 'react-redux';
import { VALID_MAIL_REGEX } from '../../utils/constant';
import axios from 'axios';
import { axiosCurrentAccountGET } from '../../axios/axios.jsx';
import { setCookie, getCookie } from '../../helpers/authorizationService';
import jwt_decode from 'jwt-decode';

const { REACT_APP_RECAPTCHA } = process.env;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            password: '',
            loginInProcess: true,
            loginResult: null,
            isEmailValid: false,
            captchaResponse: '',
        };

        this.onResolved = this.onResolved.bind(this);
    }

    componentDidMount() {
        this.setState({
            loginInProcess: false,
        });

        if (this.props.uar.isUserAuthorized) {
            this.props.history.push({
                pathname: '/onboarding',
                state: {},
            });
        }
    }

    login(e) {
        e.preventDefault();
        this.recaptcha.execute();
    }

    handleLoginFormChange(event) {
        const { value } = event.target;

        if (event.target.id === "password") {
            
            this.setState({
                password: value
            })

        } else {

        const emailValidator = VALID_MAIL_REGEX;
        !emailValidator.test(value)
            ? this.setState({
                  userEmail: value,
                  isEmailValid: false,
              })
            : this.setState({
                  userEmail: value,
                  isEmailValid: true,
              });

        }
    }

    handleEmailInputBlur(event) {
        const emailValidator = VALID_MAIL_REGEX;
        emailValidator.test(event.target.value)
            ? (event.target.className = 'form-control is-valid')
            : (event.target.className = 'form-control is-invalid');
    }

    onResolved() {
        this.setState({
            loginInProcess: true,
        });

        const email = this.state.userEmail;
        const password = this.state.password;
        const captchaResponse = this.recaptcha.getResponse();

        const payload = {
            email: email,
            password: password,
            captchaResponse: captchaResponse,
        };

        axiosLogin(payload)
            .then((result) => {                
                localStorage.setItem('email', this.state.userEmail);
                setCookie('authorizationKey', result.data, 0, 1);
                this.setState({
                    loginInProcess: false,
                });
                this.recaptcha.reset();

                // setting defaults for axios
                axios.defaults.headers.common = {
                    Authorization: `bearer ${getCookie(
                        'authorizationKey'
                    )}`};
                
                const token = getCookie('authorizationKey')
                this.props.onSetAuthorizationToken(token);
                // decoding the token

                let decodedToken = jwt_decode(token);
                let role;

                console.log(`${decodedToken.scope}`)
                
                if (decodedToken.scope.includes('ADMIN')) {
                    role = 'admin';
                } else if (
                    decodedToken.scope.includes('USER')
                ) {
                    role = 'user';
                } else {
                    role = 'signer';
                }
                this.props.onSetUserProfile(role);
                setCookie('userRole', role, 0, 1);

                axiosCurrentAccountGET().then((account) => {
                    const names =
                        account.data.firstName +
                        ' ' +
                        account.data.lastName;
                    this.props.onSetUserData(
                        names,
                        account.data.email
                    );
                    this.props.onStopLoader();
                });
            
                if (role === 'user') {
                        this.props.history.push({pathname: '/onboarding'});
                    } else if (role === 'admin') {
                        this.props.history.push('/admin/direct');
                    } else if (role === 'signer') {
                        this.props.history.push('/invitation');
                    }

            })
            .catch((err) => {
                this.setState({
                    loginInProcess: false,
                });
                const message =
                    err.response && err.response.data.message
                        ? err.response.data.message
                        : 'Login failed';
                this.props.history.push({
                    pathname: 'login/error',
                    state: { error: message },
                });
            });
    }

    render() {
        const { loginInProcess, isEmailValid } = this.state;

        return (
            <LoadingOverlay active={loginInProcess} spinner text="Logging...">
                <div className="container-fluid">
                    <div className="row login center--y bg-ghost-white">
                        <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-1 col-sm-12">
                            <div className="form-wrapper bg-white">
                                <h1 className="h2">Log in</h1>
                                <form
                                    onSubmit={(e) => this.login(e)}
                                    className="login-form mt-16">
                                    <div className="form-group mb-40">
                                        <label
                                            htmlFor="email"
                                            className="has-float-label">
                                            <input
                                                name="email"
                                                type="email"
                                                id="email"
                                                placeholder=" "
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleLoginFormChange(
                                                        e
                                                    );
                                                }}
                                                onBlur={(e) => {
                                                    this.handleEmailInputBlur(
                                                        e
                                                    );
                                                }}
                                            />
                                            <span>Work Email</span>
                                        </label>
                                    </div>
                                    <div className="form-group mb-40">
                                        <label
                                            htmlFor="password"
                                            className="has-float-label">
                                            <input
                                                name="password"
                                                type="password"
                                                id="password"
                                                placeholder=" "
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleLoginFormChange(
                                                        e
                                                    );
                                                }}
                                                onBlur={(e) => {
                                                    this.handleEmailInputBlur(
                                                        e
                                                    );
                                                }}
                                            />
                                            <span>Password</span>
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        aria-pressed="false"
                                        className="btn btn-primary m-0"
                                        disabled={!isEmailValid}>
                                        Login
                                    </button>
                                    <Recaptcha
                                        ref={(ref) => (this.recaptcha = ref)}
                                        sitekey={REACT_APP_RECAPTCHA}
                                        onResolved={() => this.onResolved()}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="login-info">
                                <i className="mdi mdi-email-check-outline"></i>
                                <p className="mt-10">
                                    Enter the email you are registered with, and
                                    we will send you a link that will sign you
                                    in instantly. The link will be valid for 30
                                    minutes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uar: state.uar,
        upr: state.upr,
        loader: state.loader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAuthorizationToken: (token) =>
        dispatch({
            type: actionTypes.SET_USER_AUTHORIZATION,
            authorizationToken: token,
        }),
    onSetUserProfile: (role) =>
        dispatch({
            type: actionTypes.SET_USER_ROLE,
            role: role,
        }),
    onSetUserData: (names, email) =>
        dispatch({
            type: actionTypes.SET_USER_PROFILE,
            names: names,
            email: email,
        }),
    onStartLoader: () =>
        dispatch({
            type: actionTypes.START_LOADER,
            isLoading: true,
        }),
    onStopLoader: () =>
        dispatch({
            type: actionTypes.STOP_LOADER,
            isLoading: false,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
