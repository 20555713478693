import * as actionTypes from '../actions';

const initialState = {
    navTree: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NAV_TREE:
            return {
                ...state,
                navTree: action.navTree,
            };
        default:
            return state;
    }
};

export default reducer;
