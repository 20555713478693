import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MARKDOWN_SOURCE_BASE_URL } from '../../../../../utils/constant.js';

export default class NetworkMapServiceTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageText: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        fetch(
            `${MARKDOWN_SOURCE_BASE_URL}/content/home/key%20services%20section/network%20map%20service%20tab.md`
        )
            .then((response) => response.text())
            .then((text) => {
                this.setState({
                    pageText: text,
                });
            });
    }

    render() {
        return (
            <div id="network-map-service-tab" className="tab-content">
                <ReactMarkdown source={this.state.pageText} />
            </div>
        );
    }
}
