import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { MARKDOWN_SOURCE_BASE_URL } from '../../utils/constant';
import './BottomBar.scss';

class BottomBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            footerText: '',
            currentYear: new Date().getFullYear(),
            
        };
    }

    
    componentDidMount() {
        fetch(`${MARKDOWN_SOURCE_BASE_URL}/content/footer/_Bottom_bar.md`)
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                this.setState({
                    footerText: `${data}`,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const cnfPaths = [
            '',
            'about',
            'minutes',
            'governance',
            'participation',
            'policy',
            'trust-root',
            'search',
        ];
        const path = this.props.location.pathname.split('/')[1];

        function determineClasses() {
            let classString = 'bottom-bar container-fluid';
            if (cnfPaths.indexOf(path) !== -1) {
                classString += ' dark';
            }
            return classString;
        }
        return (
            <div className={determineClasses()}>
                <div className="row h-100">
                    <div className="col-12 box--flex center--y center--XxX">
                        <div className="copy">
                            <ReactMarkdown source={this.state.footerText} />
                        </div>
                        <div className="privacy">
                            <Link
                                to="/about/terms-of-use"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="solo-link link-secondary">
                                terms of use
                            </Link>
                            <Link
                                to="/trust-centre/privacy-policy"
                                className="solo-link link-secondary">
                                privacy policy
                            </Link>
                            <Link
                                to="/anti-slavery-statement"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="solo-link link-secondary">
                                anti-slavery statement
                            </Link>
                            <Link
                                to="/contact/contact-us"
                                rel="noopener noreferrer"
                                className="solo-link link-secondary">
                                contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BottomBar);
