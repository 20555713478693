import React, { Fragment } from 'react';
import './Search.scss';
import Footer from './../../components/Footer/Footer';

export default class Search extends React.Component {
    render() {
        window.onpopstate = function(event) {
            window.location.reload();
        };
        return (
            <Fragment>
                <div className="search-results container-fluid">
                    <div className="row bg-white">
                        <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12">
                            <div className="gcse-search"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}
