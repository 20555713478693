import React from 'react';
import { MARKDOWN_SOURCE_BASE_URL } from '../../../utils/constant';
import LoadingOverlay from 'react-loading-overlay';
import ReactMarkdown from 'react-markdown';

export default class AntiSlaveryStatement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: '',
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        fetch(`${MARKDOWN_SOURCE_BASE_URL}/content/_Anti slavery statement.md`)
           .then((response) => {
                return response.text();
            
        }).then((data) => {
            this.setState({
                content: data,
            });
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.loading === true}
                spinner
                text="Loading...">
                <ReactMarkdown source={this.state.content}/>
            </LoadingOverlay>
        );
    }
}
