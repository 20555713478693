const firebaseConfig = {
    staging: {
        apiKey: 'AIzaSyA6XguuLstuiOx8nOnUzC20r0seCb73PFs',
        authDomain: 'cn-onboarding-staging.firebaseapp.com',
        databaseURL: 'https://cn-onboarding-staging.firebaseio.com',
        projectId: 'cn-onboarding-staging',
        storageBucket: '',
        messagingSenderId: '533730399645',
        appId: '1:533730399645:web:4022b7d71b3491b40d8b74',
    },
    prod: {
        apiKey: 'AIzaSyCNv1IhngNLKnUja4zYF55AfCm2fZKnHoM',
        authDomain: 'cordanetwork-onboarding-a4f5f.firebaseapp.com',
        databaseURL: 'https://cordanetwork-onboarding-a4f5f.firebaseio.com',
        projectId: 'cordanetwork-onboarding-a4f5f',
        storageBucket: 'cordanetwork-onboarding-a4f5f.appspot.com',
        messagingSenderId: '237753433019',
        appId: '1:237753433019:web:bf74e2cf784be37032629e',
        measurementId: 'G-P4VZCB1RNK',
    },
};

export default firebaseConfig;
