import React from 'react';
import { setCookie, getCookie } from '../../helpers/authorizationService';
import { NavLink } from 'react-router-dom';
import './PrivacyPopup.scss';

class PrivacyPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showCookiesPopup: !getCookie('corda_cookie') || !getCookie('hasConsent'),
            consentForm: {
                set: true,
                necessary: true,
                preferences: false,
                statistics: false,
                marketing: false,
            },
        };

    }

    submitConsent = (event, consentAll) => {
        event.preventDefault();
        this.setConsentCookies(consentAll);

        this.setState({
            showCookiesPopup: false,
        })
    }

    setConsentCookies = (consentAll) => {
        consentAll ? setCookie('corda_cookie', {
            set: true,
            necessary: true,
            preferences: true,
            statistics: true,
            marketing: true,
        }, 365, 0, true)
        : setCookie('corda_cookie', this.state.consentForm, 365, 0, true); 

        setCookie('hasConsent', true, 365, 0);
    }

    handleConsentOptionChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState({ consentForm: { ...this.state.consentForm, [item]: isChecked} });
    }

    render() {
        return this.state.showCookiesPopup ?
            <div className="privacy-popup">
                <h3>Our use of cookies</h3>
                <p>
                    We use necessary cookies to make our site work, as well as
                    optional cookies to help us improve use of our site. For
                    more detailed information about the cookies we use, see our &nbsp;
                    <NavLink to="/cookie-declaration">
                        Cookies Declaration
                    </NavLink>.
                </p>

                <form className="cookie-consent-form">
                    <fieldset>
                        <legend>
                            Please select one of the following options to
                            continue to our site.
                        </legend>

                        <div className="accept-btn-group">
                            <button onClick={e => this.submitConsent(e, false)} className="accept-btn" type="submit">
                                Allow cookies selected below
                            </button>
                            <button onClick={e => this.submitConsent(e, true)} className="accept-btn" type="submit">
                                Allow all cookies
                            </button>
                        </div>

                        <span className="cookies-checkbox">
                            <input
                                type="checkbox"
                                id="necessary"
                                name="necessary"
                                autoComplete="off"
                                checked={this.state.consentForm.necessary}
                                disabled={true}
                            />
                            <input name="necessary" type="hidden" value="on" />
                            <label htmlFor="necessary">Necessary</label>
                        </span>
                        <span className="cookies-checkbox">
                            <input
                                type="checkbox"
                                id="preferences"
                                name="preferences"
                                autoComplete="off"
                                checked={this.state.consentForm.preferences}
                                onChange={e => this.handleConsentOptionChange(e)}
                            />
                            <label htmlFor="preferences">Preferences</label>
                        </span>
                        <span className="cookies-checkbox">
                            <input
                                type="checkbox"
                                id="statistics"
                                name="statistics"
                                autoComplete="off"
                                checked={this.state.consentForm.statistics}
                                onChange={e => this.handleConsentOptionChange(e)}
                            />
                            <label htmlFor="statistics">Statistics</label>
                        </span>
                        <span className="cookies-checkbox">
                            <input
                                type="checkbox"
                                id="marketing"
                                name="marketing"
                                autoComplete="off"
                                checked={this.state.consentForm.marketing}
                                onChange={e => this.handleConsentOptionChange(e)}
                            />
                            <label htmlFor="marketing">Marketing</label>
                        </span>
                    </fieldset>
                </form>
            </div>
            : null;
    }
}

export default PrivacyPopup;
